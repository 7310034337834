@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Inter", sans-serif;
}
.App {
  padding: 40px 0;
  background: #393838;
  backdrop-filter: blur(5px);
}
a {
  text-decoration: none;
}
.card {
  margin: 0 auto;
  display: block;
  background-color: #161616;
  width: 320px;
  color: #2b283a;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}
.profile-info,
.cta,
.about,
.social-media {
  padding: 0 35px;
}

.profile-img > img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.profile-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.profile-name {
  font-size: 32px;
  font-weight: 700;
  color: #ffffff;
}
.profile-occupation {
  font-size: 14px;
  font-weight: 400;
  color: #f47d27;
}
.profile-website {
  font-size: 12px;
  color: #ffffff;
}

.button-group {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.cta-btn {
  width: 115px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Değişiklik burada */
  gap: 8px;
  border-radius: 6px;
  border: 1px solid black;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 13.5px;
  padding: 0 10px; /* Yeni eklenen özellik */
}

.cta-btn box-icon {
  width: 24px !important;
  height: 24px !important;
}

.cta-btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px; /* veya ikonlarınızın genişliğine uygun bir değer */
}

.cta-btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#email {
  color: #ffffff;
  border-color: #d1d5db;
}


#linkedin {
  color: white;
  background-color: #3464e8;
  border-color: #3464e8;
}
#behance {
  color: white;
  background-color: #1769ff;
  border-color: #1769ff;
}
#whatsapp {
  color: white;
  background-color: #25d366;
  border-color: #25d366;
}
#instagram {
  color: white;
  background-color: #f09433;
  border-color: #f09433;
}

#gmaps {
  font-size: 13px;
  color: white;
  background-color: #ea4335;
  border-color: #ea4335;
}

#greviews {
  font-size: 13px;
  color: white;
  background-color: #fbbc05;
  border-color: #fbbc05;
}

#facebook {
  color: white;
  background-color: #1877f2;
  border-color: #1877f2;
}

#messenger {
  color: white;
  background-color: #0084ff;
  border-color: #0084ff;
}

#telephone {
  color: #ffffff;
  border-color: #d1d5db;
}
.about {
  margin-top: 30px;
}
.section-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}
.section-content {
  font-size: 12px;
  color: #ffffff;
  line-height: 1.6;
}
.qr {
  margin-top: 30px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  background-color: #d6d3d8;
}

.qr img {
  width: 100%;
  border-radius: 10px;
}

.qr-text {
  font-size: 14px;
  font-weight: 500;
  color: #4a4e74;
  text-align: center;
}

.footer {
  background-color: #2b283a;
  border-radius: 0 0 10px 10px;
}

.footer-text {
  padding: 20px 0;
  text-align: center;
  color: white;
  font-size: 12px;
}

#ekartvizzit {
  color: rgb(234 179 8/1);
  text-decoration: inherit;
}

#ekartvizzit:hover {
  text-decoration: underline;
}

.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cta.single-column .button-group {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cta.double-column .button-group {
  display: flex;
  justify-content: center;
}

.cta .cta-btn {
  flex: 1;
  text-align: center;
}
